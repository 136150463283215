import React from "react";
import { Link } from "react-router-dom";

const Mentions = () => {
    return (
        <div className="mentions-container p-6 max-w-7xl  mx-auto">
            {/* Lien vers le menu principal avec une image modifiable */}
            <div className="menu-link mb-6 flex justify-center items-center">
                <Link to="/">
                    <img
                        src={"images/bg_new.jpg"} // Remplacez par votre lien d'image
                        alt="Retour au menu principal"
                        className="w-32 "
                    />

                </Link>
            </div>

            <h1 className="text-3xl font-bold mb-4">Mentions légales</h1>
            <h2 className="text-2xl font-semibold mb-2">1 | Présentation du site</h2>
            <p className="mb-4">
                Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du
                21 juin 2004 pour la Confiance dans l'économie numérique (L.C.E.N.), voici les
                informations légales concernant le site :
            </p>
            <ul className="mb-4 list-disc pl-6">
                <li><strong>Nom :</strong> C2ER Cyril CAUDROY</li>
                <li><strong>Adresse :</strong> 20 avenue Jean Rostand 60000 BEAUVAIS</li>
                <li><strong>Tél :</strong> 06 83 42 70 22</li>
                <li><strong>SIRET :</strong> 502 293 939 00059</li>
                <li><strong>Inscrite au RCS :</strong> Beauvais sous le numéro 502 293 939</li>
                <li><strong>Numéro TVA intracommunautaire :</strong> FR21502293939</li>
                <li><strong>Email :</strong> <a href="mailto:cyril.energetique@gmail.com"
                                                className="text-blue-500 underline">cyril.energetique@gmail.com</a></li>
            </ul>
            <p className="mb-4">
                <strong>Créateur du site :</strong> Cyril Caudroy<br/>
                <strong>Responsable de publication :</strong> Cyril Caudroy<br/>
                <strong>Webmaster :</strong> Cyril Caudroy<br/>
                <strong>Hébergeur :</strong> Wix.com Inc. - 500 Terry A François Blvd, San Francisco CA 94158
            </p>

            <h2 className="text-2xl font-semibold mb-2">2 | Description des services fournis</h2>
            <p className="mb-4">
                Le site <a href="https://www.cyril.energetique.fr"
                           className="text-blue-500 underline">www.cyril.energetique.fr</a> a pour objet de fournir des
                informations sur
                les activités de C2ER Cyril CAUDROY. Les informations sont données à titre
                indicatif et peuvent évoluer sans préavis.
            </p>

            <h2 className="text-2xl font-semibold mb-2">3 | Propriété intellectuelle et contrefaçons</h2>
            <p className="mb-4">
                Tous les contenus présents sur le site sont protégés par le Code de la Propriété
                Intellectuelle. Toute reproduction, modification ou exploitation non autorisée est
                interdite.
            </p>

            <h2 className="text-2xl font-semibold mb-2">4 | Liens hypertexte</h2>
            <p className="mb-4">
                Le site peut contenir des liens vers d'autres sites. C2ER Cyril CAUDROY ne peut
                être tenu responsable du contenu de ces sites tiers.
            </p>

            <h2 className="text-2xl font-semibold mb-2">5 | Protection des biens et des personnes - Gestion des données
                personnelles</h2>
            <p className="mb-4">
                Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux
                fichiers et aux libertés, les utilisateurs disposent d’un droit d’accès, de
                rectification et de suppression de leurs données personnelles. Pour toute demande,
                contactez-nous à : <a href="mailto:cyril.energetique@gmail.com"
                                      className="text-blue-500 underline">cyril.energetique@gmail.com</a>.
            </p>

            <h1 className="text-3xl font-bold mt-8 mb-4">Politique de confidentialité RGPD</h1>
            <h2 className="text-2xl font-semibold mb-2">Article 1 – Renseignements personnels recueillis</h2>
            <p className="mb-4">
                Lors de votre utilisation du site, nous pouvons recueillir des informations telles
                que votre nom, prénom, téléphone et adresse e-mail.
            </p>

            <h2 className="text-2xl font-semibold mb-2">Article 2 – Consentement</h2>
            <p className="mb-4">
                En remplissant un formulaire ou en fournissant vos informations, vous consentez à
                leur utilisation. Vous pouvez retirer ce consentement à tout moment en nous
                contactant à : <a href="mailto:cyril.energetique@gmail.com"
                                  className="text-blue-500 underline">cyril.energetique@gmail.com</a>.
            </p>

            <h2 className="text-2xl font-semibold mb-2">Article 3 – Divulgation</h2>
            <p className="mb-4">
                Vos informations personnelles peuvent être divulguées si la loi nous y oblige.
            </p>

            <h2 className="text-2xl font-semibold mb-2">Article 4 – Cookies</h2>
            <p className="mb-4">
                Des cookies peuvent être utilisés sur le site pour améliorer votre expérience.
                Vous pouvez configurer votre navigateur pour bloquer les cookies, mais certaines
                fonctionnalités pourraient ne pas être disponibles.
            </p>

            <h2 className="text-2xl font-semibold mb-2">Article 5 – Sécurité</h2>
            <p className="mb-4">
                Nous suivons les meilleures pratiques de sécurité pour protéger vos données
                personnelles contre tout accès non autorisé.
            </p>

            <h2 className="text-2xl font-semibold mb-2">Article 6 – Modifications</h2>
            <p className="mb-4">
                Cette politique peut être mise à jour à tout moment. Consultez-la régulièrement
                pour rester informé des modifications.
            </p>

            <p className="text-sm text-gray-500 italic">
                Pour toute question, contactez-nous à :{" "}
                <a href="mailto:cyril.energetique@gmail.com"
                   className="text-blue-500 underline">cyril.energetique@gmail.com</a> ou par courrier à Cyril
                énergétique –
                4 rue du Maréchal Maunoury – Apt 422 - 60200 Compiègne.
            </p>
        </div>
    );
};

export default Mentions;
