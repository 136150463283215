import React, {Component} from "react";
import { motion } from 'framer-motion';


class Portfolio extends Component {
    render() {
        return (

            <section
                id="portfolio"
                className=" justify-center flex items-center py-12 "
            >
                <div className="lg:max-w-screen-2xl w-full flex flex-col"
                >
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                    >
                        <div className="flex justify-center items-center">
                            <h1>Mes formations</h1>
                        </div>
                        <div
                            className="flex flex-col lg:flex-row items-center justify-center pb-10">
                            <div className=" flex lg:w-4/12 mb-10	 flex-col items-center">
                                <div className="flex  flex-col items-center justify-center">
                                    <img className="h-36 lg:h-52 aspect-square" src={"images/logo_reiki.png"}
                                         alt="Reiki Usui Shiki Ryoho"/>
                                    <p className=" text-black  mb-1 text-center">Reiki Usui Shiki
                                        Ryoho</p>
                                    <p className=" text-center">(Certifiée ShivEnergetics Reiki
                                        Academy)</p>
                                </div>
                            </div>
                            <div className="flex lg:w-4/12 mb-10 flex-col items-center">
                                <div className="flex  flex-col items-center justify-center">
                                    <img className="h-36 lg:h-52 aspect-square" src={"images/traditional_formation.png"}
                                         alt="Energétique Traditionnelle"/>
                                    <p className="text-black text-3xl text-center">Energétique Traditionnelle</p>
                                    <p className="h-7"></p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="flex justify-center items-center"
                        >
                            <div
                                className="lg:h-[400px] h-[250px] pb-6 flex justify-center items-center flex-col w-11/12	 "
                                id="bg-rounded"
                            >
                                <div className="flex justify-center items-center mb-4 lg:mb-20 "
                                     id="tarifs"
                                >
                                    <h1 className="text-center ">Déroulement d’une séance</h1>
                                </div>
                                <div className="flex justify-center items-center"
                                >
                                    <div className="mb-12 lg:mb-0">
                                        <div className="flex justify-center flex-col items-center">
                                            <p>Tous les soins se font habillés.</p>
                                            <p className="text-center">La séance dure 1h à 1h30 suivant les besoins
                                                :</p>
                                            <ul>
                                                <li className="text-center text-black">- Entretien en début de séance
                                                </li>
                                                <li className="text-center text-black">- Soin énergétique</li>
                                                <li className="text-center text-black">- Débriefing en fin de séance
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </motion.div>
                </div>
            </section>
        );
    }
}

export default Portfolio;