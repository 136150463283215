import React, {Component} from "react";
import { motion } from 'framer-motion';

class Footer extends Component {
    render() {
        if (!this.props.data) return null;



        return (
            <footer>
                <div className="">
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                    >
                        <div className="flex justify-center items-center mx-8">
                            <a
                                href="/cgu"
                                className="px-4">CGU</a>
                            <a
                                href="/cgv"
                                className="px-4">CGV</a>
                            <a
                                href="/legale"
                                className="px-4">Mentions Légales</a>

                        </div>


                        {/*<ul className="social-links">{networks}</ul>*/}

                        <ul className="mt-2">
                            <li>&copy; Design with ❤️ by Cyril Caudroy</li>
                            {/*<li>*/}
                            {/*    Design by{" "}*/}
                            {/*    <a title="Styleshout" href="http://www.styleshout.com/">*/}
                            {/*        Styleshout*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </motion.div>
                </div>

                {/*<div id="go-top">*/}
                {/*    <a className="smoothscroll text-white" title="Back to Top" href="#home">*/}
        {/*        <i className="icon-up-open"></i>*/}
        {/*    </a>*/}
        {/*</div>*/}
    </footer>
    )
        ;
    }
}

export default Footer;
