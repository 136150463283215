import React from "react";
import { Link } from "react-router-dom";

const Cgu = () => {
    return (
        <div className="cgu-container p-6 max-w-7xl mx-auto">
            {/* Lien vers le menu principal avec une image modifiable */}
            <div className="menu-link mb-6 flex justify-center items-center">
                <Link to="/">
                    <img
                        src={"images/bg_new.jpg"} // Remplacez par votre lien d'image
                        alt="Retour au menu principal"
                        className="w-32 "
                    />

                </Link>
            </div>

            <h1 className="text-3xl font-bold mb-4">Conditions générales d'utilisation</h1>
            <p className="text-gray-600 italic mb-4">En vigueur au 28/10/2024</p>

            <p className="mb-4">
                Les présentes conditions générales d'utilisation (dites « CGU ») ont pour
                objet l'encadrement juridique des modalités de mise à disposition du site et
                des services par <strong>C2ER Cyril CAUDROY</strong> et de définir les conditions
                d’accès et d’utilisation des services par « l'Utilisateur ».
            </p>
            <p className="mb-4">
                Les présentes CGU sont accessibles sur le site à la rubrique « CGU ».
            </p>

            <h2 className="text-2xl font-semibold mb-2">Article 1 : Les mentions légales</h2>
            <p className="mb-4">
                L'édition du site <a href="http://www.cyril.energetique.fr" className="text-blue-500 underline">www.cyril.energetique.fr</a> est assurée par la Société
                SARL C2ER Cyril CAUDROY au capital de 3000 euros, immatriculée au RCS de
                Beauvais sous le numéro 502 293 939 00059, dont le siège social est situé au :
                <br />
                <strong>20 avenue Jean Rostand, 60000 BEAUVAIS</strong>
            </p>
            <ul className="mb-4 list-disc pl-6">
                <li>Numéro de téléphone : 0683427022</li>
                <li>Email : <a href="mailto:cyril.energetique@gmail.com" className="text-blue-500 underline">cyril.energetique@gmail.com</a></li>
                <li>Directeur de publication : Caudroy Cyril</li>
                <li>Numéro de TVA intracommunautaire : FR21502293939</li>
            </ul>
            <p className="mb-4">
                L'hébergeur du site est <strong>Wix.com Inc.</strong>, situé au :<br />
                <strong>500 Terry A François Blvd, San Francisco CA 94158</strong><br />
                Téléphone : 0670418565
            </p>

            <h2 className="text-2xl font-semibold mb-2">ARTICLE 2 : Accès au site</h2>
            <p className="mb-4">
                Le site <a href="http://www.cyril.energetique.fr" className="text-blue-500 underline">www.cyril.energetique.fr</a> permet un accès gratuit aux services
                suivants : <strong>Informer</strong>.
            </p>
            <p className="mb-4">
                Le site est accessible gratuitement à tout Utilisateur ayant un accès à Internet.
                Tous les frais supportés pour accéder au service (matériel, connexion, etc.)
                sont à la charge de l’Utilisateur.
            </p>

            <h2 className="text-2xl font-semibold mb-2">ARTICLE 3 : Collecte des données</h2>
            <p className="mb-4">
                Le site respecte la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux
                fichiers et aux libertés. L'Utilisateur dispose d'un droit d'accès, de rectification,
                de suppression et d'opposition à ses données personnelles.
            </p>
            <p className="mb-4">
                L'Utilisateur exerce ce droit :<br />
                Par mail : <a href="mailto:cyril.energetique@gmail.com" className="text-blue-500 underline">cyril.energetique@gmail.com</a><br />
                Par voie postale : <strong>4 Rue du Maréchal Maunoury - Apt 422 - 60200 Compiègne</strong>
            </p>

            {/* Ajoutez les autres articles ici de la même manière */}

            <h2 className="text-2xl font-semibold mb-2">ARTICLE 7 : Cookies</h2>
            <p className="mb-4">
                L'Utilisateur est informé que lors de ses visites, un cookie peut être installé sur
                son navigateur. Ces cookies sont nécessaires pour améliorer l'expérience du site.
            </p>
            <p className="mb-4">
                L'Utilisateur peut désactiver les cookies via les paramètres de son navigateur.
                Certaines fonctionnalités pourraient être limitées en cas de désactivation.
            </p>

            <h2 className="text-2xl font-semibold mb-2">ARTICLE 8 : Droit applicable et juridiction compétente</h2>
            <p className="mb-4">
                La législation française s'applique aux présentes CGU. En cas de litige, les tribunaux français seront compétents.
            </p>

            <p className="text-sm text-gray-500 italic">
                CGU réalisées sur <a href="http://legalplace.fr/" className="text-blue-500 underline">http://legalplace.fr/</a>
            </p>
        </div>
    );
};

export default Cgu;
