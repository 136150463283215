import React, {Component} from "react";
import { motion } from 'framer-motion';

class Price extends Component {
    render() {
        return (
            <section
                id="price"
                className=""
            >
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
                >
                    <div className=" grid-cols-8 h-[500px] hidden lg:grid">
                        <div className=" col-span-6 flex flex-col items-center justify-center py-12">
                            <h1 className="text-center inline-block">Prendre un rendez-vous</h1>

                            <a className=" border-2 border-black rounded-full px-8 py-3"
                               href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0NYZ7VXHS3oISBuQhuzzWJLKAvFykbci_BLedv_UM5zEcGmE_TEuI767wWfHow0illD49J2Fky"
                               target="_blank"
                               rel="noreferrer"
                            >
                             <span className="text-black">
                                 Prendre rendez-vous
                             </span>
                            </a>
                            <span className="text-black p-2">ou</span>
                            <a href="tel:0683427022"
                               className="flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={2} stroke="currentColor" className="size-8 mr-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                                </svg>

                                <span className="text-black">06.83.42.70.22</span>
                            </a>
                            <span className="text-black p-2">ou</span>
                            <a href="mailto:cyril.energetique@gmail.com"
                               className="flex  justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={2} stroke="currentColor" className="size-8 mr-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                                </svg>

                                <span className="text-black">cyril.energetique@gmail.com</span>
                            </a>
                        </div>
                        <div className=" col-span-2 flex justify-center  items-center flex-col py-12  h-full"
                             id="bg-gradiant"
                        >
                            <h1>Tarif</h1>

                            <div className="aspect-square w-72 flex flex-col items-center justify-center rounded-full"
                                 id="bg-beige">
                                <div
                                    className="col-span-2 text-center flex flex-col justify-center items-center
                                r
                                ">
                                    <span className="text-2xl text-black font-bold mb-2 ">60,00 €</span>
                                    <span className="text-xl text-black">La séance</span>
                                </div>
                            </div>
                            <span className="text-xs mt-16 mb-4 text-black">Règlement en espèces </span>
                            <p className="text-xs text-black "> ou chèque uniquement</p>


                        </div>
                    </div>


                    <div className=" lg:hidden flex justify-center py-12 items-center flex-col">
                        <div className="  flex flex-col items-center justify-center py-12">
                            <h1 className="text-center inline-block">Prendre un rendez-vous</h1>

                            <a className=" border-2 border-black rounded-full px-8 py-3"
                               href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0NYZ7VXHS3oISBuQhuzzWJLKAvFykbci_BLedv_UM5zEcGmE_TEuI767wWfHow0illD49J2Fky">
                             <span className="text-black">
                                 Prendre rendez-vous
                             </span>
                            </a>
                            <span className="text-black p-2">ou</span>
                            <a href="tel:0683427022"
                               className="flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={2} stroke="currentColor" className="size-8 mr-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                                </svg>

                                <span className="text-black">06.83.42.70.22</span>
                            </a>
                            <span className="text-black p-2">ou</span>
                            <a href="mailto:cyril.energetique@gmail.com"
                               className="flex  justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={2} stroke="currentColor" className="size-8 mr-4">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                                </svg>

                                <span className="text-black">cyril.energetique@gmail.com</span>
                            </a>
                        </div>
                        <hr className="w-4/5 border-gray-500 border-1 mt-2 lg:hidden"/>
                        <div className="flex justify-center items-center py-12 flex-col">
                            <h1 className="text-center inline-block ">Tarif</h1>
                            <div className="aspect-square items-center h-48 justify-center rounded-full flex flex-col "
                                 id="round-green">
                                <span className="text-center text-white">
                                    60,00 €
                                </span>
                                <span className="text-center text-white">
                                    La séance
                                </span>

                            </div>
                            <span className="text-xs mt-16 mb-6 text-black">Règlement en espèces </span>
                            <span className="text-xs text-black">ou chèque uniquement</span>

                        </div>


                    </div>
                </motion.div>
            </section>
        );
    }
}

export default Price;
