import React, {Component} from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";
import Price from "./Components/Price";
import Cgv from "./Components/Cgv";   // Ajout du composant CGV
import Mentions from "./Components/Mentions";   // Ajout du composant Mentions
import Cgu from "./Components/Cgu";   // Ajout du composant CGU
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import {Analytics} from "@vercel/analytics/react"
// Composant pour afficher conditionnellement le Header
const ConditionalHeader = ({data}) => {
    const location = useLocation();
    // Affiche le Header uniquement sur la route "/"
    return location.pathname === "/" ? <Header data={data}/> : null;
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            resumeData: {}
        };

        ReactGA.initialize("UA-110570651-1");
        ReactGA.pageview(window.location.pathname);
    }

    getResumeData() {
        $.ajax({
            url: "./resumeData.json",
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({resumeData: data});
            }.bind(this),
            error: function (xhr, status, err) {
                console.log(err);
                alert(err);
            }
        });
    }

    componentDidMount() {
        this.getResumeData();
    }

    render() {
        return (
            <Router>
                <div className="App max-w-full overflow-x-hidden">
                    {/* Header conditionnel */}
                    <ConditionalHeader data={this.state.resumeData.main}/>
                    <Routes>
                        {/* Routes principales */}
                        <Route
                            path="/"
                            element={
                                <>
                                    <About data={this.state.resumeData.main}/>
                                    <Resume data={this.state.resumeData.resume}/>
                                    <Portfolio data={this.state.resumeData.portfolio}/>
                                    <Price data={this.state.resumeData.price}/>
                                    <Contact data={this.state.resumeData.main}/>
                                </>
                            }
                        />

                        {/* Routes CGV, CGU et Mentions légales */}
                        <Route path="/cgv" element={<Cgv/>}/>
                        <Route path="/cgu" element={<Cgu/>}/>
                        <Route path="/legale" element={<Mentions/>}/>
                    </Routes>
                    <Analytics mode="production"/>
                    <Footer data={this.state.resumeData.main || {}}/>
                </div>

            </Router>
        );
    }
}

export default App;
