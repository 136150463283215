import React, {Component} from "react";
import {motion} from 'framer-motion';

class Header extends Component {
    render() {
        if (!this.props.data) return null;


        return (
            <header id="home"
                // className="bg-white bg-no-repeat bg-cover bg-center pb-10 "
                // className="bg-white bg-no-repeat bg-top  "
            >

                <div className="banner mt-20">
                    <div className="flex w-full justify-center items-center  ">
                        <img className="lg:w-1/5 w-80 items-center" src={"images/bg_new.jpg"}
                             alt="Energétique Traditionnelle"/>
                    </div>

                    <div className="banner-text">
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 0.5}}
                        >
                            <h1 className=" hidden lg:block lg:text-3xl xl:text-6xl my-10 lg:my-20">
                                Soins énergétiques traditionnels et Reiki
                            </h1>
                            <hr className="w-full border-t-2 border-black border-2 mt-2 lg:hidden"/>

                            <h1 className=" mt-10 lg:hidden">
                                Soins énergétiques
                            </h1>
                            <h1 className=" lg:hidden">
                                traditionnels
                            </h1>
                            <h1 className=" lg:hidden mb-12">
                                et Reiki
                            </h1>


                            <hr className="w-3/4 border-t-2 border-black border-2 mt-2 hidden lg:block "/>
                            <nav id="nav-wrap">
                                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
                                    Show navigation
                                </a>
                                <a className="mobile-btn" href="#home" title="Hide navigation">
                                    Hide navigation
                                </a>

                                <ul id="nav" className="nav flex justify-center">
                                    <li className="current">
                                        <a className="smoothscroll" href="#home">
                                            Accueil
                                        </a>
                                    </li>

                                    <li>
                                        <a className="smoothscroll" href="#about">
                                            Mon histoire
                                        </a>
                                    </li>

                                    <li>
                                        <a className="smoothscroll" href="#resume">
                                            L'énergétique, c'est quoi ?
                                        </a>
                                    </li>

                                    <li>
                                        <a className="smoothscroll" href="#price">
                                            Prendre rendez-vous
                                        </a>
                                    </li>
                                    <li>
                                        <a className="smoothscroll" href="#price">
                                            tarif
                                        </a>
                                    </li>

                                    <li>
                                        <a className="smoothscroll" href="#contact">
                                            Contact
                                        </a>
                                    </li>
                                </ul>
                            </nav>

                        </motion.div>
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 0.5}}
                        >
                            {/*<h3 className="mb-10"*/}
                            {/*>{description}.</h3>*/}
                            {/*<a className=" border-2 border-black rounded-full px-8 py-3"*/}
                            {/*   href="#">*/}
                            {/* <span className="text-black">*/}
                            {/*     Prendre rendez-vous*/}
                            {/* </span>*/}
                            {/*</a>*/}
                        </motion.div>
                        {/*<Fade bottom duration={2000}>*/}
                        {/*  <ul className="social">*/}
                        {/*    <a href={github} className="button btn github-btn">*/}
                        {/*      <i className="fa fa-github"></i>*/}
                        {/*      Github*/}
                        {/*    </a>*/}
                        {/*  </ul>*/}
                        {/*</Fade>*/}
                    </div>
                </div>

                {/*<p className="scrolldown">*/}
                {/*    <a className="smoothscroll" href="#about">*/}
                {/*        <i className="icon-down-circle"></i>*/}
                {/*    </a>*/}
                {/*</p>*/}
            </header>
        );
    }
}

export default Header;
