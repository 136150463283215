import React, {Component} from "react";
import { motion } from 'framer-motion';

class Resume extends Component {
    render() {
        if (!this.props.data) return null;

        const profilepic = "images/energetique_section.png"
        return (
            <section
                id="resume"
                className="flex justify-center items-center "

            >
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
                >
                    <div className="flex flex-col lg:flex-row h-full lg:max-w-screen-2xl mb-10 ">

                        <div className="container mx-auto max-w-full px-16 lg:w-8/12 w-full">

                            <h1
                                className="flex justify-center lg:justify-start">L'énergétique, c'est quoi ?</h1>

                            <p className="text-justify	">
                                C’est une manière de se rééquilibrer.
                            </p>

                            <p className="text-justify	">
                                La vie étant faite de hauts et de bas, on se retrouve par moment avec un coup de moins
                                bien, moins d’énergie, des petites douleurs, du mal à dormir, un peu de stress, des
                                angoisses, des conflits relationnels ou encore des épreuves majeures comme une
                                séparation, un deuil ou des soucis de santé. Ces situations peuvent peser sur notre
                                moral et le corps peut parfois s’exprimer.
                            </p>
                            <p className="text-justify	">
                                Les soins énergétiques sont là pour vous accompagner dans ces moments de fragilité, en
                                faisant un gros nettoyage pour retrouver l’équilibre, apaiser les tensions, soulager les
                                douleurs et favoriser un mieux-être physique et mental.
                            </p>

                        </div>
                        <div className=" lg:w-4/12 w-full flex justify-center items-center ">
                            <img
                                className="h-60	 aspect-auto max-w-md lg:h-fit "
                                src={profilepic}
                                alt="bouda"
                            />

                        </div>

                    </div>
                </motion.div>
            </section>
        );
    }
}

export default Resume;
