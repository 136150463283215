import React, {Component} from "react";
import { motion } from 'framer-motion';


class About extends Component {
    render() {
        if (!this.props.data) return null;

        const profilepic = "images/histoire_section.png"
        return (
            <section
                id="about"
                className="flex justify-center items-center "
            >
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
                >
                    <div className="flex flex-col lg:flex-row h-full lg:max-w-screen-2xl mb-10 ">
                        <div className=" lg:w-5/12 w-full flex justify-center items-center lg:pr-28">
                            <img
                                className="h-60	lg:h-fit  "
                                src={profilepic}
                                alt="bouda"
                            />

                        </div>
                        <div className="container mx-auto px-16 max-w-full   lg:w-8/12 w-full">

                            <h1
                                className="flex justify-center lg:justify-start">Mon Histoire</h1>

                            <p className="text-justify	">Mon petit doigt me dit que tu n’es pas arrivé ici par
                                hasard, oui toi derrière ton écran.
                                Moi aussi j’ai fait la même démarche il y a quelques années. Après avoir tout essayé,
                                je me retrouvais toujours au même point, moi et mes soucis. Et comme on commençait tous
                                les deux à plus se supporter, j’ai décidé d’essayer quelque chose de nouveau, un truc
                                dont on entendait parler, mais que personne n’osait « l’énergétique ». Alors je me suis
                                lancé. </p>


                            <p className="text-justify	">
                                Ça parlait de Ying et de Yang, d’énergie, de chakras et de pleins d’autres trucs qu’on
                                ne voyait pas, « l’invisible » ils appelaient ça. Au final, c’était ni plus ni moins
                                qu’une personne qui posait ses mains sur moi dans un silence abyssal, si si je vous
                                jure. Et après une heure passée sur cette table, j’en sortais plus léger, délesté et
                                surtout rééquilibré, un peu comme leur histoire de Ying et de Yang, au point
                                d’équilibre...
                            </p>
                            <p className="text-justify	">
                                Mais comme rien n’est magique dans ce monde, quelques semaines plus tard mes soucis
                                pointaient à nouveau le bout de leurs nez. Fort décidé à m’en débarrasser pour de bon,
                                et convaincu par les bienfaits, j’ai décidé d’y retourner. Et croyait le ou non, séance
                                après séance, je me sentais de mieux en mieux. Et comme ça m’avait bien aidé, j’ai
                                décidé de me former et d’en faire mon métier, pour pouvoir aider comme on a pu m’aider,
                                une histoire d’équilibre je crois, ou de boucle bouclée à vous d’en juger !
                            </p>

                        </div>
                    </div>
                </motion.div>
            </section>
        );
    }
}

export default About;
