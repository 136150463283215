import React from "react";
import { Link } from "react-router-dom";

const Cgv = () => {
    return (
        <div className="cgv-container p-6 max-w-7xl mx-auto">
            {/* Lien vers le menu principal avec une image modifiable */}
            <div className="menu-link mb-6 flex justify-center items-center">
                <Link to="/">
                    <img
                        src={"images/bg_new.jpg"} // Remplacez par votre lien d'image
                        alt="Retour au menu principal"
                        className="w-32 "
                    />
                </Link>
            </div>

            <h1 className="text-3xl font-bold mb-4">
                Conditions Générales de Prestations de Service et de Vente
            </h1>
            <p className="text-gray-600 italic mb-4">Dernière mise à jour : Octobre 2024</p>

            <h2 className="text-2xl font-semibold mb-2">1 | Généralités</h2>
            <p className="mb-4">
                Les présentes conditions générales de prestations de service et de vente (CGPSV)
                s’appliquent, sans restriction ni réserve, à l’ensemble des prestations de services
                (ci-après les « Prestations ») proposées par <strong>C2ER Cyril CAUDROY</strong>, et ce, à
                l’ensemble de ses clients.
            </p>
            <p className="mb-4">
                L'activité de Cyril CAUDROY est régie par les dispositions du Contrat d'Appui au Projet
                d'Entreprise – loi n°2003-721 du 1er août 2003 – dont C2ER assure la gestion du
                01/10/2024 au 31/03/2025, numéro de SIRET 502 293 939 00059.
            </p>

            <h2 className="text-2xl font-semibold mb-2">2 | Caractéristiques générales des Prestations</h2>
            <p className="mb-4">
                La finalité de l’activité de <strong>C2ER Cyril CAUDROY</strong> réalisée par Cyril CAUDROY
                est exclusivement le bien-être de la personne. Conformément à la législation en vigueur,
                les soins d'accompagnement au bien-être réalisés par Cyril CAUDROY, en l'absence de
                diagnostic et de traitement thérapeutique, ne s'apparentent en rien, ni dans les
                contenus ni dans les objectifs, à la pratique de la masso-kinésithérapie, ainsi qu'à
                toute pratique médicale, thérapeutique ou encore sportive.
            </p>
            <p className="mb-4">
                Sont également exclues toutes prestations à caractère érotique ou sexuel.
            </p>
            <p className="mb-4">
                Les Prestations proposées par <strong>C2ER Cyril CAUDROY</strong> sont disponibles sur le
                site internet : <a href="https://www.cyril.energetique.fr" className="text-blue-500 underline">https://www.cyril.energetique.fr</a>.
            </p>

            <h2 className="text-2xl font-semibold mb-2">3 | Conditions de réalisation des Prestations</h2>
            <p className="mb-4">
                Les prestations sont réalisées uniquement sur rendez-vous à l'adresse suivante :
            </p>
            <p className="mb-4">
                <strong>4 Rue du Maréchal Maunoury – Apt 422 - 60200 COMPIEGNE</strong>.
            </p>
            <p className="mb-4">
                Disponibilités : du mardi au samedi, de 9h30 à 12h30 et de 13h30 à 19h30. La prise de
                rendez-vous se fait par téléphone, par mail ou via l’application Google Calendar. Toutes
                les coordonnées sont disponibles sur le site internet.
            </p>

            <h2 className="text-2xl font-semibold mb-2">4 | Contre-indications aux massages bien-être</h2>
            <p className="mb-4">
                Chaque client devra se conformer aux conseils et recommandations, notamment au niveau
                des contre-indications. Le client est informé qu’il est contre-indiqué de recevoir un
                massage bien-être sans avis médical préalable en cas de conditions spécifiques.
            </p>

            <h2 className="text-2xl font-semibold mb-2">5 | Conditions financières</h2>
            <p className="mb-4">
                Les prix sont indiqués en euros sur le site, rubrique « Tarif ». Le règlement se fait
                à la fin de la séance, en espèce ou par chèque.
            </p>
            <h3 className="text-xl font-medium mb-2">5-1. Annulation d’un rendez-vous</h3>
            <p className="mb-4">
                Toute annulation doit être effectuée au moins 24 heures avant la date du rendez-vous.
                Sinon, une facturation pourra être appliquée.
            </p>

            <h2 className="text-2xl font-semibold mb-2">6 | Incapacité de travail</h2>
            <p className="mb-4">
                En cas d'incapacité temporaire de travail de Cyril CAUDROY, les prestations seront
                replanifiées sans indemnité possible. En cas d’incapacité permanente, les engagements
                seront résiliés et les sommes perçues seront remboursées sous 30 jours.
            </p>

            <h2 className="text-2xl font-semibold mb-2">7 | Loi applicable et juridiction compétente</h2>
            <p className="mb-4">
                Les présentes CGPSV sont soumises à la législation française. En cas de litige, les
                parties devront rechercher une solution à l’amiable avant toute action judiciaire.
            </p>

            <h2 className="text-2xl font-semibold mb-2">8 | Responsabilité civile professionnelle</h2>
            <p className="mb-4">
                La responsabilité de Cyril CAUDROY ne saurait être engagée en cas de dommages liés
                à la négligence du client, notamment en cas d'omission d'information concernant des
                contre-indications.
            </p>

            <h2 className="text-2xl font-semibold mb-2">9 | Politique de confidentialité RGPD</h2>
            <p className="mb-4">
                Pour la politique de confidentialité, référez-vous à la rubrique « Mentions légales » sur
                le site : <a href="https://www.cyril.energetique.fr" className="text-blue-500 underline">https://www.cyril.energetique.fr</a>.
            </p>

            <h2 className="text-2xl font-semibold mb-2">10 | Contact</h2>
            <p className="mb-4">
                Pour toute question, vous pouvez contacter :
                <br />
                Par mail : <a href="mailto:cyril.energetique@gmail.com" className="text-blue-500 underline">cyril.energetique@gmail.com</a>
                <br />
                Par courrier : <strong>C2ER Cyril CAUDROY, 4 Rue du Maréchal Maunoury – Apt 422 – 60200 COMPIEGNE</strong>.
            </p>
        </div>
    );
};

export default Cgv;
